import React from 'react';

export const RovoIcon = () => (
	<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.01252 0.272861C8.39569 -0.0860701 7.63608 -0.0909079 7.01541 0.258348C7.00655 0.264837 6.99727 0.270923 6.98758 0.276567L6.98749 0.276617L6.98616 0.277396C6.5869 0.510134 6.28495 0.869785 6.11906 1.28884C6.02701 1.52384 5.97772 1.77775 5.97772 2.0391L5.97772 4.73473L9.32565 6.68347L9.32585 6.68358C10.1481 7.1605 10.6523 8.0421 10.6523 8.99811L10.6523 15.9571C10.6523 16.2693 10.598 16.574 10.4962 16.8594L14.9876 14.2439L14.9881 14.2436C15.6153 13.8799 16 13.2078 16 12.4776L16 5.51861C16 4.79132 15.613 4.11625 14.9877 3.75237L14.9876 3.75232L9.01252 0.272861Z"
			fill="#0C66E4"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.0223 15.9609C10.0223 16.2225 9.97285 16.4768 9.88057 16.712C9.71443 17.1312 9.41213 17.4908 9.01249 17.7234L9.0124 17.7234C9.00271 17.729 8.99343 17.7351 8.98458 17.7416C8.36393 18.0909 7.60436 18.086 6.98755 17.7272L6.98747 17.7271L1.01241 14.2477L1.01232 14.2476C0.386987 13.8837 0 13.2087 0 12.4814L0 5.52235C0 4.79218 0.384718 4.12005 1.01191 3.75635L5.50381 1.14058C5.40202 1.42596 5.34771 1.73065 5.34771 2.04285L5.34771 9.00186C5.34771 9.95778 5.85176 10.8393 6.67389 11.3162L6.67433 11.3165L10.0223 13.2652V15.9609Z"
			fill="#0C66E4"
		/>
	</svg>
);
