export const LOCAL_STORAGE_KEY = 'atlassian.unified-search.toggle-feature';

/**
 * Different states of the user in search experience:
 * OPT_IN: User is opt in unified search, but has the ability to opt out
 * OPT_OUT: User is opt out of unified search, but has the ability to opt back in
 * OPT_IN_DISMISSED: User is opt in unified search, but cannot opt back out
 * OPT_OUT_DISMISSED: User is opt out of unified search, but cannot opt back in
 * OPT_BACK_IN: User is opt back in unified search from legacy search, but cannot opt back out
 * OPT_BACK_IN_DISMISSED: User is opt back in unified search from legacy search, but they do not see the pop up flag anymore
 */
export enum UserPreferenceState {
	OPT_IN = 'OPT_IN',
	OPT_OUT = 'OPT_OUT',
	OPT_IN_DISMISSED = 'OPT_IN_DISMISSED',
	OPT_OUT_DISMISSED = 'OPT_OUT_DISMISSED',
	OPT_BACK_IN = 'OPT_BACK_IN',
	OPT_BACK_IN_DISMISSED = 'OPT_BACK_IN_DISMISSED',
}

type UserPreferences = {
	state: UserPreferenceState;
};

const defaultPreference: UserPreferences = {
	state: UserPreferenceState.OPT_IN,
};

type ParsedData = { [key: string]: UserPreferences };

export const setUnifiedSearchOptIn = (userId: string | null) => {
	if (userId === null) {
		return;
	}
	const parsedData = getParsedData(userId);
	parsedData[userId] = { state: UserPreferenceState.OPT_IN };
	setLocalStorageItem(LOCAL_STORAGE_KEY, JSON.stringify(parsedData));
};

export const setUnifiedSearchOptOut = (userId: string | null) => {
	if (userId === null) {
		return;
	}
	const parsedData = getParsedData(userId);

	parsedData[userId] = { state: UserPreferenceState.OPT_OUT };
	setLocalStorageItem(LOCAL_STORAGE_KEY, JSON.stringify(parsedData));
};

export const setUnifiedSearchOptBackIn = (userId: string | null) => {
	if (userId === null) {
		return;
	}

	const parsedData = getParsedData(userId);
	parsedData[userId] = { state: UserPreferenceState.OPT_BACK_IN };
	setLocalStorageItem(LOCAL_STORAGE_KEY, JSON.stringify(parsedData));
};

export const setUnifiedSearchDismissed = (userId: string | null) => {
	if (userId === null) {
		return;
	}
	const parsedData = getParsedData(userId);
	if (getUnifiedSearchUserState(userId) === UserPreferenceState.OPT_IN) {
		parsedData[userId] = { state: UserPreferenceState.OPT_IN_DISMISSED };
	} else if (getUnifiedSearchUserState(userId) === UserPreferenceState.OPT_OUT) {
		parsedData[userId] = { state: UserPreferenceState.OPT_OUT_DISMISSED };
	} else if (getUnifiedSearchUserState(userId) === UserPreferenceState.OPT_BACK_IN) {
		parsedData[userId] = { state: UserPreferenceState.OPT_BACK_IN_DISMISSED };
	}
	setLocalStorageItem(LOCAL_STORAGE_KEY, JSON.stringify(parsedData));
};

export const isUnifiedSearchOptIn = (userId: string | null): boolean => {
	if (userId === null) {
		return false;
	}

	// if user clears local storage, then by default they should see Unified Search
	if (getLocalStorageItem(LOCAL_STORAGE_KEY) === null) {
		return true;
	}

	const state = getUnifiedSearchUserState(userId);
	return (
		state === UserPreferenceState.OPT_IN ||
		state === UserPreferenceState.OPT_IN_DISMISSED ||
		state === UserPreferenceState.OPT_BACK_IN ||
		state === UserPreferenceState.OPT_BACK_IN_DISMISSED
	);
};

export const isUnifiedSearchOptBackIn = (userId: string | null): boolean => {
	if (userId === null) {
		return false;
	}

	const state = getUnifiedSearchUserState(userId);
	return state === UserPreferenceState.OPT_BACK_IN;
};

export const isUnifiedSearchDismissed = (userId: string | null): boolean => {
	if (userId === null) {
		return false;
	}

	const state = getUnifiedSearchUserState(userId);
	return (
		state === undefined ||
		state === UserPreferenceState.OPT_IN_DISMISSED ||
		state === UserPreferenceState.OPT_OUT_DISMISSED ||
		state === UserPreferenceState.OPT_BACK_IN_DISMISSED
	);
};

export const getUnifiedSearchUserState = (userId: string | null): UserPreferenceState | null => {
	if (userId === null || getLocalStorageItem(LOCAL_STORAGE_KEY) === null) {
		return null;
	}
	const parsedData = getParsedData(userId);
	return parsedData[userId].state;
};

const getParsedData = (userId: string): ParsedData => {
	const data = getLocalStorageItem(LOCAL_STORAGE_KEY);
	let parsedData: ParsedData = {};

	// if data retrieved is not in json format, then we want to remove it
	if (data) {
		try {
			parsedData = JSON.parse(data);
		} catch (error) {
			localStorage.removeItem(LOCAL_STORAGE_KEY);
		}
	}

	if (!parsedData[userId]) {
		parsedData[userId] = { ...defaultPreference };
	}
	return parsedData;
};

const setLocalStorageItem = (key: string, value: string) => {
	localStorage.setItem(key, value);
};

const getLocalStorageItem = (key: string): string | null => {
	return localStorage.getItem(key);
};
