import { defineMessages } from 'react-intl-next';

export const messages = defineMessages({
	searchIconLabel: {
		id: 'quick_find_dialog_aria_label',
		defaultMessage: 'Quick Find',
		description: 'Label text for search icon',
	},
	clearIconLabel: {
		id: 'input_clear_icon_label',
		defaultMessage: 'Clear',
		description: 'Label text for clear icon',
	},
});
