import React from 'react';

import AKSearchIcon from '@atlaskit/icon/core/migration/search';
import type { Size } from '@atlaskit/icon/types';
import { N200, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

interface Props {
	isExpanded: boolean;
	/**
	 * @default 'small'
	 */
	size?: Size;
	primaryColor?: string;
	defaultColor?: string;
	label?: string;
}

export const LegacySearchIcon = ({
	isExpanded,
	size = 'small',
	primaryColor = token('color.text', N800),
	defaultColor = token('color.text.subtlest', N200),
	label = '',
}: Props) => (
	<AKSearchIcon
		label={label}
		LEGACY_primaryColor={isExpanded ? primaryColor : defaultColor}
		LEGACY_size={size}
		// NOTE: color do not accept string type but here primaryColor is string, so need to use any
		color={isExpanded ? (primaryColor as any) : defaultColor}
	/>
);
