// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/react';
import { TooltipPrimitive } from '@atlaskit/tooltip';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { token } from '@atlaskit/tokens';
import {
	collapsedSearchInputWidth,
	dialogWidth,
	inputFieldHeight,
	grid,
	dialogPageTakeoverWidth,
	atlassianNavigationHeight,
} from '../style-utils';
import { B200, N70, N100, N200, N500, N900 } from '@atlaskit/theme/colors';
import { media } from '@atlaskit/primitives';
import { fg } from '@atlaskit/platform-feature-flags';

import type { SearchCSS } from '../theme';
import { componentWithCondition } from '../internal/ff-component';

const expandedPlaceholderColor = token('color.text.subtlest', '#B5BEC9');

export interface SearchInputFieldProps {
	isExpanded: boolean;
}

export interface IsExpandedProp {
	isExpanded: boolean;
}

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const MobileSearchIconWrapper = styled.span<IsExpandedProp>`
	display: none;
	@media screen and (max-width: ${dialogPageTakeoverWidth}px) {
		display: ${(props) => (props.isExpanded ? 'none' : 'block')};
	}
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DesktopSearchIconWrapper = styled('span')<SearchCSS & IsExpandedProp>`
	width: ${grid.multiple(2).px};
	height: ${grid.multiple(2).px};
	position: absolute;
	top: ${grid.px};
	left: ${grid.px};
	color: ${(props) => (props.isExpanded ? token('color.text', N900) : props.default.color)};
	display: inline-flex;
	align-items: baseline;

	:focus {
		box-shadow: 0 0 0 2px ${token('color.border.focused', B200)};
		border-radius: 100%;
		outline: none;
	}

	// Override the out-of-the-box SearchIcon for vertical centering.
	& > span[role='img'] {
		line-height: 0;
	}

	@media screen and (max-width: ${dialogPageTakeoverWidth}px) {
		display: none;
	}
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const InputBoxOverlayRight = styled('div')`
	position: absolute;
	display: flex;
	top: 0;
	right: 0;
	height: 100%;
	align-items: center;
	margin: auto 0;

	@media screen and (max-width: ${dialogPageTakeoverWidth}px) {
		right: 24px;
	}
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ClearIconWrapper = styled('div')<SearchCSS>`
	width: ${grid.multiple(2).px};
	height: ${grid.multiple(2).px};
	padding: 5px ${token('space.075', '6px')};
	color: ${token('color.text.subtlest', N70)};
	background-color: transparent;

	@media screen and (max-width: ${dialogPageTakeoverWidth}px) {
		display: none;
	}

	:hover {
		cursor: pointer;
		color: ${token('color.icon', N500)};
	}

	& > span > svg {
		vertical-align: unset;
	}
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ArrowIconWrapper = styled('div')<SearchCSS>`
	display: none;
	margin: 0 ${grid.px};
	height: ${grid.multiple(4).px};
	padding-bottom: ${grid.multiple(0.25).px};
	color: ${(props) => props.default.color};

	@media screen and (max-width: ${dialogPageTakeoverWidth}px) {
		display: flex;
		align-items: center;
	}

	:hover {
		border-radius: 100%;
		background-color: ${(props) => props.hover.backgroundColor};
		color: ${(props) => props.hover.color};
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchInputContainer = styled('div')<
	SearchCSS & IsExpandedProp & { shouldFillContainer?: boolean }
>(
	// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	css`
		position: relative;
		max-width: 100%;
		${
			/* eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ' '
		}
		@media screen and (max-width: ${dialogPageTakeoverWidth}px) {
			${/* eslint-enable */ ''}
			width: 100%;
		}
	`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		const boundedStyles = css({
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values
			width: props.isExpanded ? dialogWidth : collapsedSearchInputWidth,
		});

		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values
		const fluidStyles = css({
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values
			width: '100%',
		});

		const styles = props.shouldFillContainer ? fluidStyles : boundedStyles;

		// todo - this should be another class
		if (props.isExpanded) {
			// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			return css`
				${styles}
				${
					/* eslint-disable @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''
				}
				@media screen and (max-width: ${dialogPageTakeoverWidth}px) {
					width: 100%;
					height: ${atlassianNavigationHeight};
					background-color: ${props.default.backgroundColor};
					${/* eslint-enable */ ''}
					display: flex;
					align-items: center;
					max-width: initial;
				}
			`;
		}
		return styles;
	},
);

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
const SearchInputFieldOld = styled.input<SearchCSS & IsExpandedProp>`
	height: ${inputFieldHeight.px};
	width: 100%;
	padding: 0px ${token('space.150', '12px')} 0px 30px;
	box-sizing: border-box;
	outline: none;
	border-radius: 5px;
	font: ${token('font.body')};
	${(props) => `
    border: ${token('border.width', '1px')} solid ${
			props.isExpanded ? props.focus.borderColor : props.default.borderColor
		};
    box-shadow: ${
			props.isExpanded
				? `inset 0 0 0 ${token('border.width', '1px')} ${
						props.focus.borderColor
					}, -12px 0 24px 0 ${props.default.backgroundColor}`
				: 'none'
		};
    background-color: ${
			props.isExpanded ? props.focus.backgroundColor : props.default.backgroundColor
		};
    color: ${props.isExpanded ? props.focus.color : props.default.color};

    &::placeholder {
      color: ${props.isExpanded ? expandedPlaceholderColor : props.default.color};
    }
    :focus {
      border: ${token('border.width', '1px')} solid ${props.focus.borderColor};
    }
    @media screen and (max-width: ${dialogPageTakeoverWidth}px) {
      ${
				props.isExpanded
					? `
            padding: 0 ${grid.multiple(1.5).px} ${grid.multiple(0.25).px};
            margin: 0 ${grid.multiple(3).px} 0 0;`
					: `
            display: none;
          `
			}
    }
  `}
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
const SearchInputFieldWithMobileFix = styled.input<SearchCSS & IsExpandedProp>`
	height: ${inputFieldHeight.px};
	width: 100%;
	padding: 0px ${token('space.150', '12px')} 0px 30px;
	box-sizing: border-box;
	outline: none;
	border-radius: 5px;
	/* font needs to be a minimum of 16px to prevent iOS Safari from zooming in on focus. Smaller font size is appled in breakpoint below */
	/* Refer to @atlaskit/textfield for more details */
	font: ${token('font.body.large')};
	${(props) => `
    border: ${token('border.width', '1px')} solid ${
			props.isExpanded ? props.focus.borderColor : props.default.borderColor
		};
    box-shadow: ${
			props.isExpanded
				? `inset 0 0 0 ${token('border.width', '1px')} ${
						props.focus.borderColor
					}, -12px 0 24px 0 ${props.default.backgroundColor}`
				: 'none'
		};
    background-color: ${
			props.isExpanded ? props.focus.backgroundColor : props.default.backgroundColor
		};
    color: ${props.isExpanded ? props.focus.color : props.default.color};

    &::placeholder {
      color: ${props.isExpanded ? expandedPlaceholderColor : props.default.color};
    }
    :focus {
      border: ${token('border.width', '1px')} solid ${props.focus.borderColor};
    }
    @media screen and (max-width: ${dialogPageTakeoverWidth}px) {
      ${
				props.isExpanded
					? `
            padding: 0 ${grid.multiple(1.5).px} ${grid.multiple(0.25).px};
            margin: 0 ${grid.multiple(3).px} 0 0;`
					: `
            display: none;
          `
			}
    }
  `}
	${media.above.xs} {
		font: ${token('font.body')};
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchInputField = componentWithCondition(
	() => fg('jira_mobile_search_responsiveness'),
	SearchInputFieldWithMobileFix,
	SearchInputFieldOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const HiddenTooltip = styled(TooltipPrimitive)({
	display: 'none',
});

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SSRsearchInputField = styled('input')`
	height: ${inputFieldHeight.px};
	width: 100%;
	padding: 0px ${token('space.150', '12px')} 0px 30px;
	box-sizing: border-box;
	outline: none;
	border-radius: 5px;
	font: ${token('font.body')};
	border: ${() => `${token('border.width', '1px')} solid ${token('color.border.input', N100)}`};
	background-color: ${token('color.background.input', 'rgb(255, 255, 255)')};
	color: ${token('color.text.subtlest', N200)};

	:hover {
		background-color: ${token('color.background.input.hovered', 'rgb(255, 255, 255)')};
	}

	:focus {
		color: ${token('color.text', N900)};
		box-shadow: ${() =>
			`inset 0 0 0 ${token('border.width', '1px')} ${token('color.border.input', N100)}`};
	}

	@media screen and (max-width: ${dialogPageTakeoverWidth}px) {
		display: none;
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SSRsearchInputWrapper = styled('div')({
	position: 'relative',
	'@media screen and (min-width: 781px)': {
		minWidth: '200px',
	},
	height: '32px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SSRformContainer = styled('div')({
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SSRsearchInputContainer = styled('div')<{ shouldFillContainer?: boolean }>`
	position: relative;
	max-width: 100%;
	@media screen and (max-width: ${dialogPageTakeoverWidth}px) {
		width: 100%;
	}
	width: ${(props) => {
		if (props.shouldFillContainer) {
			return '100%';
		}

		return collapsedSearchInputWidth;
	}};
`;

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SSRdesktopSearchIconWrapper = styled('span')`
	width: ${grid.multiple(2).px};
	height: ${grid.multiple(2).px};
	position: absolute;
	top: ${grid.px};
	left: ${grid.px};
	color: rgb(107, 119, 140);
	display: inline-flex;
	align-items: baseline;

	@media screen and (max-width: ${dialogPageTakeoverWidth}px) {
		display: none;
	}
`;

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SSRmobileSearchIconWrapper = styled('span')`
	display: none;
	padding: ${grid.multiple(0.5).px};
	padding-right: ${token('space.025', '2px')};
	color: rgb(52, 69, 99);

	@media screen and (max-width: ${dialogPageTakeoverWidth}px) {
		display: flex;
	}
`;
